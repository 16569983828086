
a {
  color: black;
  text-decoration: none;
}
input[type='text'],
input[type='number'],
textarea {
  font-size: 16px;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 4px;
}
::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, .5);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}

html, body, #root {
  margin: 0;
  font-family: "Mountains of Christmas";
  height: 100% !important;
  text-align: center;
}

.App {
  height: 55%;
  width: 100%;
  top: 20%;
  position: fixed;
  margin: auto;
  justify-content: center;
  display: flex;
  align-items: center;
}

.App-header {
  background-color: #fff9c2;
  padding: 5px;
  min-height: 20vh;
  justify-content: center;
  color: black;
  font-family: "Mountains of Christmas";
  width: 100%;
  box-shadow: 0 2px 2px 0 rgba(0,0,0,0.1);
  position: fixed;
}

input {
    border: 3px solid white;
    border-radius: 5px;
    padding: 15px;
    width: 80%;
    background: rgba(255,255,255,0.6);
    margin: 0 0 10px 0;
}

.Amatic_SC {
  font-family: "Amatic SC", cursive;
}

.Bungee_Shade {
  font-family: "Bungee Shade", cursive;
}
.Charmonman {
  font-family: "Charmonman", cursive;
}
.Raleway {
  font-family: "Raleway", sans-serif;
  font-weight: 400;
}

.Lato {
  font-family: "Lato", sans-serif;
}

.Lobster {
  font-family: "Lobster", cursive;
}

.Sacramento {
  font-family: "Sacramento", cursive;
}

.Gamja_Flower {
  font-family: "Gamja Flower", cursive;
}

.Cabin_Sketch {
  font-family: "Cabin Sketch", cursive;
}

.Luckiest_Guy {
  font-family: "Luckiest Guy", cursive;
}

.Faster_One {
  font-family: "Faster One", cursive;
}

.Gloria_Hallelujah {
  font-family: "Gloria Hallelujah", cursive;
}

.Lobster_Two {
  font-family: "Lobster Two", cursive;
}

.Indie_Flower {
  font-family: "Indie Flower", cursive;
}

.Oswald {
  font-family: "Oswald", sans-serif;
}

.Permanent_Marker {
  font-family: "Permanent Marker", cursive;
}

.Poppins {
  font-family: "Poppins", sans-serif;
}

.Rock_Salt {
  font-family: "Rock Salt", cursive;
}

.Special_Elite {
  font-family: "Special Elite", cursive;
}

.Unlock {
  font-family: "Unlock", sans-serif;
}

.Fredericka_the_Great {
  font-family: "Fredericka the Great", cursive;
}

.Berkshire_Swash {
  font-family: "Berkshire Swash", cursive;
}

.Mountains_of_Christmas {
  font-family: "Mountains of Christmas", cursive;
}

.Creepster {
  font-family: "Creepster", cursive;
}

.Stalemate {
  font-family: "Stalemate", cursive;
}

.Gaegu {
  font-family: "Gaegu", cursive;
}

.Coiny {
  font-family: "Coiny", cursive;
}

.Righteous {
  font-family: "Righteous", cursive;
}


.Crafty_Girls {
  font-family: "Crafty Girls", cursive;
}

.Poiret_One {
  font-family: "Poiret One", cursive;
}
